import React, { useState } from 'react';

const InputSearch = () => {
  // Sample user data
  const users = ['Mason Mount', 'Declan Rice', 'John Stones', 'Harry Kane', 'Jack Grealish'];
  
  // State to handle the search input and filtered users
  const [searchTerm, setSearchTerm] = useState('');

  // Filter the users based on the search term
  const filteredUsers = users.filter(user => 
    user.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="App" style={{ textAlign: 'center', padding: '20px', backgroundColor: '#d9fce2' }}>
      {/* Search Input */}
      <input
        type="text"
        id="searchInput"
        data-testid="searchInput"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          width: '200px',
          marginBottom: '20px',
        }}
      />

      {/* Display Filtered User Names */}
      <div>
        {filteredUsers.map((user, index) => (
          <p key={index} className="userName" style={{ fontSize: '18px', margin: '5px 0' }}>
            {user}
          </p>
        ))}
      </div>
    </div>
  );
};

export default InputSearch;


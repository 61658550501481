// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  -webkit-user-select: none;
}

.css-1oqqzyl-MuiContainer-root {
  padding: 0px !important;
}

.landing-page {
  padding: 0px !important;
  background-color: #131e1e !important;
  max-width: 425px;
  margin: auto;
  font-family: "Manrope" !important;
}

.landing-page .CTA-coupon .banner {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  width: 300px;
  display: flex;
  padding: 18px 13px 10px;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
}

.landing-page .CTA-coupon .details {
  width: 265px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding-left: 13px;
}

.landing-page .CTA-coupon .invite {
  display: flex;
  height: 35px;
  width: 160px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  margin-top: 16px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
  border: 0px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 13px;
}

.landing-page .raffle-cta-container {
  margin: 60px 0;
  height: 400px;
}

.landing-page .raffle-cta-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  width: 330px;
  display: block;
  margin: auto;
}

.landing-page .raffle-cta-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 5px;
  display: inline;
}

.landing-page .raffle-cta-container .carousel-inner .carousel-item {
  padding: 5% 13% 0;
}

.landing-page .raffle-cta-container .view-all {
  display: flex;
  height: 35px;
  width: 160px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  margin-top: 16px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
  border: 0px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 13px;
  margin: auto;
}

.landing-page .earn-raffle-cta-container {
  padding: 15px 0 0;
  height: 375px;
}

.landing-page .earn-raffle-cta-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  width: 330px;
  display: block;
  margin: auto;
}

.landing-page .earn-raffle-cta-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 5px;
  display: inline;
}

.landing-page .earn-raffle-cta-container .carousel-inner .carousel-item {
  padding: 5% 13% 0;
}

.landing-page .earn-raffle-cta-container .view-all {
  display: flex;
  height: 35px;
  width: 160px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  margin-top: 16px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
  border: 0px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 13px;
  margin: auto;
}

.landing-page .container-1 .heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.landing-page .container-1 .heading .blr-pulse-logo {
  height: 36px;
  margin: 20px 0 16px;
}

.landing-page .container-1 .heading p {
  background: linear-gradient(274.19deg,
      #ebbd67 15.62%,
      #ffeac3 46.63%,
      #e1b667 87.79%);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
}

.landing-page .container-1 .heading .heading-1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 0;
}

.landing-page .container-1 .heading .heading-2 {
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 0;
}

.landing-page .container-1 .heading .member-card img {
  height: 79px;
  margin: 30px 0 30px;
}

.landing-page .container-1 .heading .view-reward-wallet {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: white;
  text-decoration: none;
}

.landing-page .container-1 .heading .view-reward-wallet .vector-right {
  height: 14px;
  padding-left: 10px;
  margin-bottom: 4px;
}

.landing-page .container-2 {
  margin-top: 30px;
  height: 425px;
}

.landing-page .container-2 .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
}

.landing-page .container-2 .heading p {
  color: #f1cb84 !important;
  margin-bottom: 5px;
  display: inline;
}

.landing-page .container-2 .carousel-inner .carousel-item {
  padding: 5% 13% 0;
}

.landing-page .container-3 .earn-points-container {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-left: 32px;
}

.landing-page .container-3 .earn-points-container .earn {
  color: rgba(240, 202, 131, 0.6);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
}

.landing-page .container-3 .earn-points-container .points-quantity {
  color: #f1ca83;
  font-size: 24px;
  font-weight: 500;
}

.landing-page .container-3 .earn-points-container .points-quantity p {
  margin-bottom: 0px;
}

.landing-page .container-3 .earn-points-container .points-quantity div {
  display: flex;
  color: #f1ca83;
}

.landing-page .container-3 .earn-points-container .points-quantity .colorChange {
  display: block;
  color: #fff;
}

.landing-page .container-3 .earn-points-container .on-spent {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  display: flex;
}

.landing-page .container-3 .earn-points-container .on-spent p {
  color: #f1ca83;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 2px;
}

.landing-page .container-3 .earn-points-container .transition {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  margin-top: 8px;
}

.landing-page .container-3 .earn-points-container .transition img {
  width: 14.2px;
}

.landing-page .container-4 {
  margin-top: 30px;
  height: 465px;
}

.landing-page .container-4 .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
}

.landing-page .container-4 .heading p {
  color: #f1cb84 !important;
  margin-bottom: 5px;
  display: inline;
}

.landing-page .container-4 .carousel-inner .carousel-item {
  padding: 5% 25% 0;
}

.landing-page .container-5 {
  background-color: #172323;
  height: 345px;
  padding-top: 32px;
}

.landing-page .container-5 .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
}

.landing-page .container-5 .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.landing-page .container-5 .carousel-inner .carousel-item {
  padding: 0% 12%;
}

.landing-page .container-5 .carousel-inner .carousel-item div img {
  width: 12.419px;
  height: 12.419px;
}

.invite {
  display: flex;
  height: 35px;
  width: 153px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(to bottom, white 50%, #d7a445 100%);
  margin-top: 40px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}

.invite button {
  display: flex;
  height: 30px;
  width: 147px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 0px;
  background: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.carousel-indicators {
  margin-bottom: -30px !important;
}

.carousel-indicators button {
  height: 4px !important;
  width: 4px !important;
  background-color: #fff !important;
  margin-top: 1.5px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid !important;
  border-radius: 25px;
}

.carousel-indicators .active {
  height: 6px !important;
  width: 6px !important;
  margin-top: 0px !important;
}

.landing-page .container-6 {
  margin-top: 30px;
  height: 470px;
}

.landing-page .container-6 .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
}

.landing-page .container-6 .heading p {
  color: #f1cb84 !important;
  margin-bottom: 5px;
}

.landing-page .container-6 .carousel-inner .carousel-item {
  padding: 5% 25% 0;
}

.landing-page .container-7 .refer-earn-container {
  height: 255px;
  padding-top: 32px;
  padding-left: 24px;
}

.landing-page .container-7 .refer-earn-container img {
  height: 28px;
  margin-bottom: 12px;
}

.landing-page .container-7 .refer-earn-container .banner {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  margin-top: 40px;
}

.landing-page .container-7 .refer-earn-container .banner p {
  color: #f1cb84;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 8px;
}

.landing-page .container-7 .refer-earn-container .details {
  width: 230px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.landing-page .container-7 .refer-earn-container .details img {
  height: 14px;
  margin-bottom: 2px;
}

.landing-page .container-7 .refer-earn-container .invite {
  display: flex;
  height: 35px;
  width: 153px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(to bottom, white 50%, #d7a445 100%);
  margin-top: 16px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}

.landing-page .container-7 .refer-earn-container .invite button {
  display: flex;
  height: 30px;
  width: 147px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 0px;
  background: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.landing-page .container-7 .refer-earn-container .invite button a {
  text-decoration: none;
  color: #000;
}

.landing-page .container-8 {
  margin-top: 30px;
  height: 465px;
}

.landing-page .container-8 .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
}

.landing-page .container-8 .heading p {
  color: #f1cb84 !important;
  margin-bottom: 5px;
}

.landing-page .container-8 .carousel-inner .carousel-item {
  padding: 5% 25% 0;
}

.landing-page .container-9 {
  height: 370px;
  background-color: #172323;
  padding-top: 32px;
  text-align: center;
}

.landing-page .container-9 .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
}

.landing-page .container-9 .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.landing-page .container-9 .sponsor-logo img {
  -webkit-filter: grayscale(100%);
  max-height: 68.52px;
  max-width: 100%;
}

.landing-page .container-9 .sponsor-logo-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.landing-page .container-9 .sponsor-logo {
  height: 68.52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-9 img {
  width: -webkit-fill-available;
  -webkit-filter: grayscale(100%);
}

.sponsor-list-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 32.78px;
  text-align: center;
  color: white;
  display: flex;
  padding: 40px 20px 0;
}

.sponsor-list-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.sponsor-list-container .sponsor-logo img {
  /* -webkit-filter: grayscale(100%); */
  max-height: 102.37px;
  max-width: 100%;
}

.sponsor-list-container .sponsor-logo-grid {
  display: grid;
  grid-template-columns: auto auto;
}

.sponsor-list-container .sponsor-logo {
  height: 102.37px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

del {
  color: rgba(255, 255, 255, 0.5);
}

.carousel-inner img {
  border-radius: 12.632px;
}

.transactions-container {
  color: white;
  background-color: #131e1e !important;
  max-width: 425px;
  min-height: 850px;
  margin: auto;
  font-family: "Manrope" !important;
}

.background-logo-container {
  background: url("https://d3hc1c528irei0.cloudfront.net/ui/PlainBgs/TransactionsTopbg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 0px 0px 16px 16px;
}

.transactions-container .middle-vertical-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 0 16px;
}

.spend-points-mid {
  width: 340px;
  height: 134px;
  background: url("https://d3hc1c528irei0.cloudfront.net/ui/EarningCoins/PointExpiry.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 25px auto 40px;
}

.spend-points-mid .points-expiring {
  padding: 18px 24px;
}

.spend-points-mid .points-expiring .heading {
  color: rgba(240, 202, 131, 0.6);
}

.spend-points-mid .points-expiring .points-contain {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #f1ca83;
}

.spend-points-mid .points-expiring .points-contain .coin-logo {
  height: 24px;
  width: 24px;
  margin-right: 4px;
}

.spend-points-mid .points-expiring .spend-points {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.transactions-container .middle-vertical-image .img-pulse-logo {
  height: 63px;
}

.transactions-container .middle-vertical-image .logo-heading {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  text-transform: uppercase;
  color: #f1cb84;
  margin: 0;
  letter-spacing: 1.5px;
}

.membership-details {
  width: 340px;
  height: 250px;
  background: url("https://d3hc1c528irei0.cloudfront.net/ui/MembershipCards/HighlandMC.png");
  background-size: 340px 220px;
  background-repeat: no-repeat;
  padding: 25px 18px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.membership-details .name-expiry-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.membership-details .name-expiry-container .name-member-container {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.membership-details .name-expiry-container .name {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.membership-details .name-expiry-container .name .coin-logo {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}

.membership-details .name-expiry-container .point-and-expiry-container .name {
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  display: flex;
}

.membership-details .name-expiry-container .member-till {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
}

.membership-details .annual-plan {
  font-size: 12px;
  font-weight: 600;
  line-height: 16.39px;
  letter-spacing: 0.13em;
  text-align: left;
  margin-top: 70px;
}

.membership-details .annual-plan .plan-name {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.13em;
  text-align: left;
}

.membership-details .annual-plan .membership-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.transactions-container .points-history {
  width: 100%;
  padding: 0px 16px;
}

.transactions-container .points-history .heading {
  color: white;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-top: 32px;
}

.transactions-container .points-history .heading .golden {
  color: #f1ca83;
}

.transactions-container .points-history .table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0px;
}

.transactions-container .points-history .table .details {
  height: 70px;
  background-color: #172928;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  border-radius: 4px;
}

.transactions-container .points-history .table:first-child .details {
  border-bottom: 1px solid #f1cb83;
}

.transactions-container .points-history .table .details .points-logo {
  max-height: 44px;
  max-width: 44px;
  margin-right: 12px;
}

.transactions-container .points-history .table .details .retail-section {
  display: flex;
  flex-direction: column;
}

.transactions-container .points-history .table .details .retail-section .retail-name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: white;
  background-color: #172928;
  padding: 0;
}

.transactions-container .points-history .table .details .retail-section .points-valid {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  opacity: 0.7;
  color: white;
  background-color: #172928;
  padding: 0;
}

.transactions-container .points-history .table .details .points-gained {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: end;
  padding: 0;
}

.transactions-container .points-history .table .details .points-gained .points-plus {
  color: #f1ca83;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  background-color: #172928;
  padding: 0;
}

.transactions-container .points-history .table .details .points-gained .points-expiry {
  color: #f1ca83;
  opacity: 0.7;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  background-color: #172928;
  padding: 0;
}

.transactions-container .points-history .view-transactions {
  margin-top: 10px;
  display: flex;
  padding-bottom: 40px;
  align-items: center;
  cursor: pointer;
}

.transactions-container .points-history .view-transactions .button {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #f1ca83;
  background-color: unset;
}

.transactions-container .points-history .view-transactions .transactions-logo {
  width: 16px;
  height: 16px;
  opacity: 0px;
}

.history-container {
  color: white;
  background-color: #131e1e !important;
  padding: 16px;
  max-width: 425px;
  margin: auto;
  font-family: "Manrope" !important;
  min-height: 900px;
}

.history-container .history-heading {
  font-size: 24px;
  line-height: 32px;
  padding: 24px auto;
  margin-bottom: 24px;
  padding-top: 8px;
  color: #f1ca83;
}

.history-container .tab-container {
  display: flex;
  justify-content: space-between;
  height: 35px;
  overflow-x: auto;
  gap: 10px;
  cursor: pointer;
}

.history-container .tab-container::-webkit-scrollbar {
  display: none;
}

.history-container .tab-container .tab {
  height: 100%;
  text-transform: uppercase;
  color: #f1ca83;
  opacity: 0.4;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 16px;
  padding: 0 8px;
  align-items: center;
  display: flex;
  letter-spacing: 0.1em;
}

.history-container .tab-container .selected-tab {
  background-color: #f1ca83;
  color: #131e1e;
  opacity: 1;
}

.history-container .date-container {
  display: flex;
  padding-bottom: 12px;
  margin-top: 25px;
  justify-content: space-between;
  border-bottom: 0.5px solid #ffffff;
}

.history-container .date-container .date {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.7;
}

.history-container .table-container {
  margin-top: 16px;
}

.history-container .table-history-container:first-child .table-container .table-items:first-child .details {
  border-bottom: 1px solid var(--Rewards-Color, #f1cb83);
}

.history-container .table-container .details {
  height: 70px;
  background-color: #172928;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
}

.history-container .table-container .details .points-logo {
  max-height: 44px;
  max-width: 44px;
  margin-right: 12px;
  border-radius: 5px;
}

.history-container .table-container .details .retail-section {
  display: flex;
  flex-direction: column;
}

.history-container .table-container .details .retail-section .retail-name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: white;
  background-color: #172928;
  padding: 0;
}

.history-container .table-container .details .retail-section .points-valid {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  opacity: 0.7;
  color: white;
  background-color: #172928;
  padding: 0;
}

.history-container .history-tab-container {
  background-color: #131e1e;
  position: sticky;
  top: 0px;
  z-index: 2;
  padding-bottom: 15px;
}

.history-container .table-container .details .points-gained {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: end;
  padding: 0;
}

.history-container .table-container .details .points-gained .points-plus {
  color: #f1ca83;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  background-color: #172928;
  padding: 0;
}

.history-container .table-container .details .points-gained .points-expiry {
  color: #f1ca83;
  opacity: 0.7;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  background-color: #172928;
  padding: 0;
}

.no-history-container-scroll {
  overflow: hidden;
}

.history-container.disabled {
  pointer-events: none;
  opacity: 0.9;
  -webkit-user-select: none; /* Prevent text selection */
  touch-action: none; /* Prevent touch actions */
  overflow: hidden;
}

.bit-details-popup {
  min-height: 400px;
  max-width: 425px;
  margin: auto;
  border-radius: 12px 12px 0 0;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 24px;
  padding-bottom: 65px;
}

.bit-details-popup .bit-details-heading-container {
  display: flex;
  justify-content: space-between;
}

.bit-details-popup .bit-details-heading-container .bit-details-heading {
  font-size: 20px;
  font-weight: 500;
}

.bit-details-popup .bit-details-heading-container .bit-details-close img {
  height: 20px;
}

.bit-details-popup .bit-details-heading-container-2 {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  letter-spacing: -0.6px;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F4F4F4;
}

.bit-details-popup .bit-details-heading-container-2 .bit-details-logo-id {
  display: flex;
  flex-direction: column;
}

.bit-details-popup .bit-details-heading-container-2 .bit-details-logo-id div img {
  height: 28px;
}

.bit-details-popup .bit-details-heading-container-2 .bit-details-name-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bit-details-popup .bit-details-transactions-container {
  margin: 15px 0;
  min-height: 190px;
  max-height: 325px;
  background-color: #F4F4F4;
  border-radius: 8px;
  overflow-y: auto;
}

.bit-details-popup .bit-details-transactions-container .bit-details-total-points {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding: 15px;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-container {
  background-color: #ffffff;
  margin: 0px 15px 15px;
  border-radius: 8px;
  min-height: 77px;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-container .bit-details-points-breakup {
  display: flex;
  padding: 14px;
  gap: 12px;
  align-items: center;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-container .divider-line {
  height: 1px;
  background-color: #F4F4F4;
  margin: 0 15px;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-1 {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #F4F4F4;
  border-radius: 6px;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-1 img {
  max-width: 45px;
  max-height: 45px;
  border-radius: 5px;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-21 {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-211 {
  font-size: 13px;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-212 {
  font-size: 10px;
  font-weight: 200;
}

.bit-details-popup .bit-details-transactions-container .bit-details-points-breakup-22 {
  font-size: 13px;
  font-weight: 600;
}

.bit-details-popup .bit-details-transactions-container .bit-details-general-columns {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  padding: 0 15px 15px;
}

.bit-details-popup .bit-details-done {
  display: flex;
  height: 46px;
  background-color: #0A6B71;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 100%;
  border: 0px;
}

.refer-container {
  background-repeat: no-repeat;
  background-image: url("https://images.ctfassets.net/vel1ejdzohgb/2gj2m1rCIrYEloANW2sLB8/b7a8d329ff8f1c340e663b5ef56c227b/referral01.png");
  min-height: 800px;
  background-size: cover;
  padding: 16px;
  background-color: #131e1e !important;
  max-width: 425px;
  margin: auto;
  font-family: "Manrope" !important;
}

.refer-container .copy-notification {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: white;
  margin-left: 8px;
  letter-spacing: 0.5px;
  background-color: #f1cb83;
  color: black;
  border-radius: 15px;
  padding: 20px;
  text-align: justify;
}

.refer-container .heading {
  padding-top: 8px;
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  color: white;
  margin-left: 8px;
  letter-spacing: 0.5px;
}

.refer-container .heading .heading-inside {
  color: #f1cb84;
}

.refer-container .sub-heading {
  color: white;
  opacity: 0.8;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  margin-left: 8px;
  margin-top: 12px;
  width: 315px;
}

.refer-container .sub-heading .sub-heading-inside {
  font-weight: 600;
}

.refer-container .bullet-points {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: white;
  margin-left: 8px;
}

.refer-container .bullet-points .bullet {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}

.refer-container .bullet-points .bullet .bullet-image {
  height: 32px;
  width: 32px;
  margin-right: 5px;
}

.refer-container .code-section {
  margin-left: 8px;
  margin-top: 27px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: white;
}

.refer-container .code-section .code-image {
  height: 24px;
  width: 24px;
}

.refer-container .invite-button {
  margin-left: 8px;
  display: flex;
  align-items: center;
  margin-top: 17px;
  height: 35px;
  justify-content: center;
  width: 150px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  background-color: white;
  border-radius: 100px;
  border: none;
  color: black;
  text-decoration: none;
}

.slick-list {
  overflow: hidden !important;
  /* Allow overflow to show the full center slide */
  padding: 0 20px;
  /* Add some padding to the sides if needed */
}

.slick-slide {
  padding: 10px;
  /* Add padding to create spacing between slides */
}

.raffle-container-2 .slick-slide {
  padding: 10px 20px 10px 0;
  /* Add padding to create spacing between slides */
}

.raffle-container-2 .slick-list{
  padding-left: 24px;
}

.slick-center .slide {
  transform: scale(1.05) !important;
  /* Scale the center slide by 5% */
  transition: transform 0.5s ease;
  z-index: 2;
  /* Bring the centered slide on top */
}

.raffle-container-2 .slick-center .slide, .raffle-cta-container .slick-center .slide {
  transform: scale(1) !important;
  /* Scale the center slide by 5% */
  transition: transform 0.5s ease;
  z-index: 2;
  /* Bring the centered slide on top */
}

.raffle-container-2 .slick-center .slide, .earn-raffle-cta-container .slick-center .slide {
  transform: scale(1) !important;
  /* Scale the center slide by 5% */
  transition: transform 0.5s ease;
  z-index: 2;
  /* Bring the centered slide on top */
}

.slick-slide img,
.slick-slide div {
  max-width: 100%;
  height: auto;
  display: block;
}

.slick-list img {
  border-radius: 12.6px;
}

.slider-container img {
  max-height: 100%;
  object-fit: cover;
  /* Ensures the image covers the slide without overflowing */
}

.sponsor-list-container {
  color: white;
  background-color: #131e1e !important;
  max-width: 425px;
  min-height: 850px;
  margin: auto;
  font-family: "Manrope" !important;
}

.sponsor-list-container img {
  width: -webkit-fill-available;
  -webkit-filter: grayscale(100%);
}

.raffle-container {
  color: white;
  background-color: #131e1e !important;
  max-width: 425px;
  min-height: 850px;
  margin: auto;
  font-family: "Manrope" !important;
}

.raffle-container .raffle-container-1 {
  padding: 50px 24px 15px;
}

.raffle-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 15px;
  text-align: center;
  color: white;
  display: flex;
}

.raffle-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.raffle-container .raffle-container-1 .headingDesc {
  font-size: 14px;
  width: 250px;
  margin: 15px 0 10px;
}

.raffle-container .raffle-container-1 .raffleCount {
  display: flex;
  align-items: center;
  gap: 10px;
}

.raffle-container .raffle-container-1 .raffleCount1 {
  background: linear-gradient(274.19deg, #D7A446 26.62%, #FFDA95 52.63%, #D7A446 64.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  font-weight: 500;
}

.raffle-container .raffle-container-3 {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: 100% 120%;
  background-repeat: no-repeat;
  background-position-y: -20px;
}

.raffle-container .raffle-container-3 .invite {
  display: flex;
  height: 35px;
  width: 160px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  margin-top: 16px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
  border: 0px;
  font-size: 14px;
  font-weight: 600;
  margin: 30px auto;
  color: black;
}

.raffle-container .raffle-container-4 {
  display: flex;
  flex-direction: row;
  padding: 18px 24px 0;
}

.raffle-container .raffle-container-4 .raffle-container-41 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.raffle-container .raffle-container-4 .raffle-container-42 {
  display: flex;
  flex-direction: column;
}

.raffle-container .raffle-container-2 .tab-container {
  padding: 0 0 0 24px;
}

.raffle-container .raffle-container-2 .winnerCta {
  display: flex;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.raffle-container .raffle-container-2 .activeCta {
  display: flex;
  background: #4DCB95;
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.raffle-container .raffle-container-2 .expiredCta {
  display: flex;
  background: #B42A2A;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.raffle-container .tab-container {
  display: flex;
  height: 35px;
  overflow-x: auto;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.raffle-container .tab-container::-webkit-scrollbar {
  display: none;
}

.raffle-container .tab-container .tab {
  height: 100%;
  text-transform: uppercase;
  color: #f1ca83;
  opacity: 0.4;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 25px;
  padding: 0 10px;
  align-items: center;
  display: flex;
  letter-spacing: 0.1em;
}

.raffle-container .tab-container .selected-tab {
  background-color: #f1ca83;
  color: #131e1e;
  opacity: 1;
}

.all-raffles-container {
  color: white;
  background-color: #131e1e !important;
  max-width: 425px;
  min-height: 850px;
  margin: auto;
  font-family: "Manrope" !important;
  padding: 0 20px 25px;
}

.all-raffles-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 15px;
  text-align: center;
  color: white;
  display: flex;
  margin-bottom: 5px;
}

.all-raffles-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.all-raffles-container .all-raffles-container-2 {
  margin-top: 10px;
}

.all-raffles-container .all-raffles-container-2 .winnerCta {
  display: flex;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.all-raffles-container .all-raffles-container-2 .activeCta {
  display: flex;
  background: #4DCB95;
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.all-raffles-container .all-raffles-container-2 .expiredCta {
  display: flex;
  background: #B42A2A;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.all-raffles-container .tab-container {
  display: flex;
  height: 35px;
  overflow-x: auto;
  gap: 10px;
  cursor: pointer;
}

.all-raffles-container .tab-container::-webkit-scrollbar {
  display: none;
}

.all-raffles-container .tab-container .tab {
  height: 100%;
  text-transform: uppercase;
  color: #f1ca83;
  opacity: 0.4;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 25px;
  padding: 0 10px;
  align-items: center;
  display: flex;
  letter-spacing: 0.1em;
}

.all-raffles-container .tab-container .selected-tab {
  background-color: #f1ca83;
  color: #131e1e;
  opacity: 1;
}


.coupon-container {
  color: white;
  background-color: #131e1e !important;
  max-width: 425px;
  min-height: 850px;
  margin: auto;
  font-family: "Manrope" !important;
}

.coupon-container .coupon-container-1 {
  padding: 50px 24px 15px;
}

.coupon-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 15px;
  text-align: center;
  color: white;
  display: flex;
}

.coupon-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.coupon-container .coupon-container-1 .headingDesc {
  font-size: 14px;
  width: 250px;
  margin: 15px 0 10px;
}

.coupon-container .coupon-container-1 .raffleCount {
  display: flex;
  align-items: center;
  gap: 10px;
}

.coupon-container .coupon-container-1 .raffleCount1 {
  background: linear-gradient(274.19deg, #D7A446 26.62%, #FFDA95 52.63%, #D7A446 64.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  font-weight: 500;
}

.coupon-container .coupon-container-3 {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: 100% 120%;
  background-repeat: no-repeat;
  background-position-y: -20px;
  margin-top: 10px;
}

.coupon-container .coupon-container-3 .invite {
  display: flex;
  height: 35px;
  width: 160px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  margin-top: 16px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
  border: 0px;
  font-size: 14px;
  font-weight: 600;
  margin: 30px auto;
  color: black;
}

.coupon-container .coupon-container-4 {
  display: flex;
  flex-direction: row;
  padding: 18px 24px 0;
}

.coupon-container .coupon-container-4 .coupon-container-41 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.coupon-container .coupon-container-4 .coupon-container-42 {
  display: flex;
  flex-direction: column;
}

.coupon-container .coupon-container-2 .tab-container{
  padding: 0 0 0 24px;
}

.coupon-container .coupon-container-2 .winnerCta {
  display: flex;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.coupon-container .coupon-container-2 .activeCta {
  display: flex;
  background: #4DCB95;
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.coupon-container .coupon-container-2 .expiredCta {
  display: flex;
  background: #B42A2A;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.coupon-container .tab-container {
  display: flex;
  height: 35px;
  overflow-x: auto;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.coupon-container .tab-container::-webkit-scrollbar {
  display: none;
}

.coupon-container .tab-container .tab {
  height: 100%;
  text-transform: uppercase;
  color: #f1ca83;
  opacity: 0.4;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 25px;
  padding: 0 10px;
  align-items: center;
  display: flex;
  letter-spacing: 0.1em;
}

.coupon-container .tab-container .selected-tab {
  background-color: #f1ca83;
  color: #131e1e;
  opacity: 1;
}

.all-coupons-container {
  color: white;
  background-color: #131e1e !important;
  max-width: 425px;
  min-height: 850px;
  margin: auto;
  font-family: "Manrope" !important;
  padding: 0 20px 25px;
}

.all-coupons-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 15px;
  text-align: center;
  color: white;
  display: flex;
  margin-bottom: 5px;
}

.all-coupons-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.all-coupons-container .all-coupons-container-2 {
  margin-top: 10px;
}

.all-coupons-container .all-coupons-container-2 .winnerCta {
  display: flex;
  background: linear-gradient(274.19deg, #D7A446 25.62%, #FFDA95 48.63%, #D7A446 72.79%);
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.all-coupons-container .all-coupons-container-2 .activeCta {
  display: flex;
  background: #4DCB95;
  color: black;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.all-coupons-container .all-coupons-container-2 .expiredCta {
  display: flex;
  background: #B42A2A;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0px 8px;
  width: fit-content;
}

.all-coupons-container .tab-container {
  display: flex;
  height: 35px;
  overflow-x: auto;
  gap: 10px;
  cursor: pointer;
}

.all-coupons-container .tab-container::-webkit-scrollbar {
  display: none;
}

.all-coupons-container .tab-container .tab {
  height: 100%;
  text-transform: uppercase;
  color: #f1ca83;
  opacity: 0.4;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 25px;
  padding: 0 10px;
  align-items: center;
  display: flex;
  letter-spacing: 0.1em;
}

.all-coupons-container .tab-container .selected-tab {
  background-color: #f1ca83;
  color: #131e1e;
  opacity: 1;
}

#productImage {
  position: relative;
}

#productImage:before {
  content: "";
  position: absolute;
  top: 44%;
  left: -7.6px;
  width: 18px;
  height: 18px;
  border-bottom: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #121e1e;
  transform: rotate(90deg);
  box-sizing: border-box;
}

.all-prizes-container {
  color: white;
  background-color: #131e1e !important;
  max-width: 425px;
  min-height: 850px;
  margin: auto;
  font-family: "Manrope" !important;
}

.all-prizes-container .all-prizes-container-1 {
  padding: 40px 24px 0;
}

.all-prizes-container .heading {
  font-size: 24px;
  font-weight: 100;
  line-height: 15px;
  text-align: center;
  color: white;
  display: flex;
  padding-top: 35px;
}

.all-prizes-container .heading p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}

.all-prizes-container .draw-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: white;
  display: flex;
  letter-spacing: 0.2px;
  padding-bottom: 10px;
}

.all-prizes-container .draw-date p {
  color: #f1cb84 !important;
  margin-bottom: 20px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

const getConfig = () => {
  let key = localStorage.getItem('x_api_key');
  let jwtToken = localStorage.getItem('jwtToken');
  const headers = {
    // 'x-api-key': key,
    'Content-Type': 'application/json',
    'Authorization': jwtToken
  };
  return headers;
};
const validateConfig = () => {
  // let key = localStorage.getItem('x_api_key');

  // let jwtToken = localStorage.getItem('jwtToken');

  const headers = {
    // 'x-api-key': key,
    'Content-Type': 'application/json'
  };
  return headers;
};
const getSrcPath = () => {
  let src = 'https://bialuattenantapp.loyaltycloud1.com';
  if (localStorage.getItem('source_path') == 'https://api.apm13.gravty.io') {
    src = 'https://bialprodtenantapp.loyaltycloud1.com';
  } else {
    src = 'https://bialuattenantapp.loyaltycloud1.com';
  }
  return src;
};
export { getConfig, getSrcPath, validateConfig };